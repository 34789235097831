.reviewPanel {
  width: 50%;
  animation: slideIn 0.2s ease;
}

.questionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 1px solid #f1f0f2;
  margin-top: -12px;
  padding-top: 16px;
}

.linkHelpCenter {
  color: #494550;
  text-decoration: underline;
}
.card {
  box-shadow: 0px 40px 72px 0px #21253529;
  position: sticky;
  top: 40px;
  // Height - spacing - header
  max-height: calc(100vh - 40px - 70px);
  overflow-y: auto;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
