.iconWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation: pulse 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  color: #8b4aff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
