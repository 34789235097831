.group {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap ;
}

.scoreCtn {
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--score-bg);
  color: var(--score-color);
}

.score1 { --score-bg: var(--colors-alert-400, #E71A22); --score-color: var(--colors-white) }
.score2 { --score-bg: var(--colors-warning-100, #FDE3CC); --score-color: var(--colors-black) }
.score3 { --score-bg: var(--colors-warning-400, #E77309); --score-color: var(--colors-white) }
.score4 { --score-bg: var(--colors-success-100, #DFF6EC); --score-color: var(--colors-black) }
.score5 { --score-bg: var(--colors-success-400, #008850); --score-color: var(--colors-white) }

[data-testid='illustrated-message'] {
  padding: 36px;
}
