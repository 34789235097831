.wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.5rem;
  min-height: 380px;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;

  & > div {
    width: 345px;
  }
}
