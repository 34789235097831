.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 320px;
  padding: 20px;
  background-color: var(--colors-white);
  border-radius: 8px;
  border: 2px solid var(--colors-gray-70);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.logoWrapper {
  height: 3rem;
  margin-bottom: 1.5rem;

  & > svg,
  img {
    height: 3rem;
    width: auto;
  }
}

.details {
  display: flex;
  flex-flow: column;
  flex: 1;
  gap: 0.5rem;
}

.name {
  margin: 0;
  line-height: 24px;
  font-weight: 600;
}

.description {
  color: var(--colors-gray-600);
  margin: 8px 0 24px 0;
  font-weight: 400;
  line-height: 24px;
}
