.accordionItem {
  border-bottom: none;
  font-weight: 400;
}

.accordionControl {
  font-size: 14px;
  line-height: 1.5rem;
  padding: 12px;
}

.accordionLabel {
  font-weight: 500;
  padding: 0;
  color: var(--colors-black);
}

.accordionContent {
  padding: 0 12px 12px;
  line-height: 1.5rem;
}

.accordionChevron {
  height: 24px;
  margin-bottom: auto;
}
