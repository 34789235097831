.outerCard {
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
}

.outerCard:not(:last-child) {
  margin-bottom: 24px;
}

.defaultBg {
  background-color: var(--colors-gray-70, #f5f5f6);
}

.infoBg {
  background-color: var(--color-primary-30, #fbf8ff);
}

.badge {
  width: fit-content;
  max-width: 100%;

  span {
    width: 100%;

    div {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
