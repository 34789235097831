.cardCtn {
  overflow-y: auto;
}

.detailsCtn {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 0;
}

.innerCard {
  border-radius: 8px;
  width: 100%;
  background-color: var(--colors-white);
}

.innerCardSection {
  padding: 12px;
}

.defaultBorder {
  border: 1px solid var(--colors-gray-90, #e9e8eb);
}

.defaultTopBorder:not(:first-child) {
  border-top: 1px solid var(--colors-gray-90, #e9e8eb);
}

.informativeBorder {
  border: 1px solid var(--color-functional-info-100, #f4edff);
}

.informativeTopBorder:not(:first-child) {
  border-top: 1px solid var(--color-functional-info-100, #f4edff);
}

.innerCardSectionAccordion {
  button[aria-expanded='true'] {
    &:hover {
      background-color: transparent;
    }
  }

  &:first-child {
    button {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
  &:last-child {
    button:not([aria-expanded='true']) {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}
