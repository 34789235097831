.dashboardContainer {
  border-radius: 0.5rem;
  background-color: var(--colors-white);
  padding: 1.5rem 1.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contentGreetingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.icon {
  display: flex;
  font-size: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  min-width: 56px;
  border-radius: 50%;
  background-color: rgb(255, 249, 233);
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

.contentBannerWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: nowrap;
  width: 100%;
  height: 216px;
  max-height: 216px;
  border-radius: 0.5rem;
  background-color: var(--colors-gray-60);
  overflow: hidden;
}

.leftColumn,
.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.bannerHeart {
  align-self: flex-end;
  width: 80px;
  margin: 1rem 1rem 0 4rem;
}

.bannerCoin {
  align-self: flex-start;
  height: 70px;
  width: 70px;
  margin: 1rem 4rem 0 1rem;
}

.bannerText {
  display: flex;
  text-align: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;

  span {
    padding-top: 1rem;
    color: var(--colors-gray-600);
  }
}
