@import '../../GrantProgramCandidateDetailsContainer.module.scss';

.fadeInFromTop {
  animation: fadeInFromTop 0.3s ease;
}

@keyframes fadeInFromTop {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
